.video {
  display: flex;
  justify-content: center;
  margin: 10px;
  text-align: center;
  font-size: 14px;
}

.video div div div {
  margin-top: 10px;
  margin-bottom: 10px;
}

button.btn.btn-primary {
  background-color: #326fbc;
  border: none;
  border-radius: 39px;
  color: #ffffff;
  display: block;
  font-size: 20px;
  height: 47px;
  letter-spacing: 0;
  line-height: 24px;
  margin: auto;
  padding: 10px 20px;
  width: 210px;
}

@media only screen and (max-width: 400px) {
  .video {
    font-size: 12px;
  }

  .video div div div {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  button.btn.btn-primary {
    font-size: 16px;
    height: 40px;
    width: 200px;
  }
}
