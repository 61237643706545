.App {
  background-color: #004cac;
  color: #ffffff;
  cursor: default;
  min-height: 100vh;
}
a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
